<template>

  <div class="main">
    <div class="main_logo">
    </div>
    <div class="main_body">

      <div class="login_register_body">
        <div class="main_switch">
          登录
        </div>
        <a-alert :message="errorMessage" type="error" v-if="errorMessage !== ''" show-icon style="color: red!important;"/>
        <div class="login" >
          <a-input v-model:value="loginUser" placeholder="手机号或邮箱" class="input" allow-clear defaultValue=""/>
          <a-input-password v-model:value="loginPas" placeholder="密码" class="input" allow-clear defaultValue=""/>

          <div style="text-align: right">
<!--            <a-button style="padding-right: 0;" type="link"  >忘记密码</a-button>-->
          </div>


          <a-button type="primary" block @click="login" style="margin-top: 46px" :loading="loading">登录</a-button>
        </div>


      </div>
    </div>
  </div>

</template>

<script>

import request from "umi-request";
import {message} from "ant-design-vue";

export default {
  name: 'loginPage',
  components: {},
  data() {
    return {
      loading:false,
      errorMessage: "",
      loginUser: "",
      loginPas: "",
    }
  },
  methods: {

    emptyCheck: function () {
        if (this.loginUser === "") {
          this.errorMessage = "请输入账号"
          return;
        } else if (this.loginPas === "") {
          this.errorMessage = "请输入密码"
          return;
        }
      this.errorMessage = ""
    },
    login: function () {
      let _this = this
      this.emptyCheck()
      this.loading = true;
      request.post('/api/user' , {
        requestType:"form",
        data: {
          type: "login",
          username :this.loginUser,
          password:this.loginPas
        },
      }).then(function (res) {
        // console.log(res)

        if (res.code === 0){
          _this.loading = false;
          _this.$emit('loginSuccess', _this.loginUser);
        }else{
          _this.loading = false;

          message.error(res.msg);
        }

        _this.loginUser = ""
        _this.loginPas = ""
      }).catch((e) => {
        console.log(e)
        message.error('网络异常');
      });
    },
  }
}
</script>

<style>


.main {
  display: flex;
  flex-direction: column;
}

.main_body {
  display: flex;
  position: relative;
  width: 100%;
  /*height: 490px;*/
  background: white;

}

.login_code, .login_register_body {
  /*padding: 30px;*/
}

.login_code {
  flex: 3;
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  border-right: 1px solid #ebebeb;
  text-align: center;
}

.code img {
  padding: 10px;
  border: #ebebeb 1px solid;
  border-radius: 10px;
  width: 120px;
  height: 120px;
  margin-bottom: 40px;
}

.login_register_body {
  display: flex;
  flex-direction: column;
  flex: 4;
  /*padding-top: 50px;*/
}

.login, .register {
  width: 100%;
  text-align: left;
}

.input {
  border: none !important;
  border-bottom: 1px solid #d9d9d9 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  margin-bottom: 20px;
}

.input:focus, .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border-color: #40a9ff !important;

}

.main_switch {
  text-align: left;
  margin-bottom: 30px;
}

.register_tip {
  text-align: left;
  font-size: 0.7rem;
  color: #999;

}
</style>
