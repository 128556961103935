import { createRouter,createWebHashHistory} from "vue-router";
// const handle = () => import("../components/handle.vue") //定义路由使用的文件
const Exhibition = () => import("../components/ExhibitionList.vue") //定义路由使用的文件


const routes = [
    {

        path: "/",
        redirect:'/product',
        component: () => import("../components/productList.vue")
    },
    {

        path: "/product",
        name: "product",
        component: () => import("../components/productList.vue")
    },
    {

        path: "/Exhibition",
        name: "Exhibition",
        component:Exhibition
    },
    {

        path: "/Banner",
        name: "Banner",
        component: () => import("../components/bannerList.vue")
    },
    {
        path: "/guest",
        name: "guest",
        component: () => import("../components/guestData.vue")
    },
    {
        path: "/user",
        name: "user",
        component: () => import("../components/userSetting.vue")
    },
    {
        path: "/userList",
        name: "userList",
        component: () => import("../components/userList.vue")
    },
    {
        path: "/message",
        name: "message",
        component: () => import("../components/message.vue")
    },
    {
        path: "/messageinfo",
        name: "messageinfo",
        component: () => import("../components/messageInfo.vue")
    }
]

//导出router
export const router = createRouter({
    history: createWebHashHistory(), //不同的历史记录模式详情看：https://router.vuejs.org/zh/guide/essentials/history-mode.html
    routes: routes
})
