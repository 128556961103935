<template>
  <div class="big">
    <div class="header_bac">
      <div class="header body">
        <div class="header_left header_item">
          <div class="logo">
            <img src="./assets/static/img/logo.png">
          </div>
          <div class="header_title">
            <span >后台管理平台</span>
          </div>
        </div>
        <div class="header_right header_item">
          <div class="logged_user_name">
            <span v-if="loggedUserName !== ''">用户:{{ loggedUserName }}</span>
          </div>
          <div class="header_decorate">
            <img src="./assets/static/img/flag.png">
          </div>
        </div>

      </div>
    </div>

    <div class="main body"  v-if="loggedUserName !== ''">
      <div class="side_nav" >
          <span v-for="(v,i) in navList" v-bind:key="i" class="side_nav_item">
            <router-link :to="v.path"  @click="clickRouter(i)">
              <span :class="nowRoute===i? 'isSelected':''"  v-if=" i !== 5||loggedUserName === 'admin'">{{ v.name }}</span>
            </router-link>
          </span>

      </div>

      <div class="main_view">
        <router-view
            @userQuite="userQuite"

        ></router-view>

      </div>


    </div>
    <a-skeleton v-else style="height: calc(100% - 150px);"/>
    <div class="footer"></div>

    <a-modal
        v-model:visible="loginVisible"
        :maskClosable="false"
        :closable="false"
        :keyboard="false"
        :centered="true"
        :footer="null"
        wrap-class-name="full-modal"
    >
      <login-page
          @loginSuccess="logged">

      </login-page>


    </a-modal>
  </div>


</template>

<script>
// import {ref} from "vue";

import LoginPage from "@/components/loginPage";
import request from "umi-request";
import {message} from "ant-design-vue";

import { useRoute } from 'vue-router';

const router=useRoute


export default {
  name: 'App',
  components: {LoginPage},
  data() {
    return {
      loginVisible: false,
      nowRoute: 0,
      loggedUserName: "",
      navList: [
        {
          name: "产品中心",
          path: "/product"
        },
        {
          name: "展览回顾",
          path: "/Exhibition"
        }, {
          name: "Banner",
          path: "/Banner"
        }, {
          name: "流量统计",
          path: "/guest"
        },

        {
          name: "联系我们",
          path: "/message"
        },
        {
          name: "用户管理",
          path: "/userList"
        },{
          name: "用户",
          path: "/user"
        },
      ]
    }
  },
  methods: {
    userQuite:function (){
      this.loggedUserName = ""
      this.loginVisible = true;
      message.error('重新登录');
    },
    logged: function (userName) {
      this.loggedUserName = userName
      this.loginVisible = false
    },
    clickRouter: function (num) {
      this.nowRoute = num
      this.tokenCheck();
    },
    tokenCheck: function () {
      let _this = this
      request.post('/api/user', {
        requestType: "form",
        data: {
          type: "info",
        },
      }).then(function (res) {
        if (res["code"] === 0) {
          _this.loggedUserName = res.info.username
          _this.loginVisible = false
          if (_this.loggedUserName == ""){
            message.info('欢迎回来' + res.info.username);
          }

        } else {
          _this.loggedUserName = ""
          _this.loginVisible = true;
          message.error(res.msg);
        }

      }).catch((e) => {
        _this.loginVisible = true;
        console.log(e)
        message.error('网络异常');
      });

    },
    tokenExtend: function () {
      // console.log("验证")
      let _this = this
      request.post('/api/user', {
        requestType: "form",
        data: {
          type: "info"
        },
      }).then(function (res) {
        if (res["code"] !== 0) {
          _this.loggedUserName = ""
          _this.loginVisible = true;
          console.log(res.msg)
          // message.error(res.msg);
        }

      }).catch((e) => {
        console.log(e)
        // message.error('网络异常');
      });

    },
  },
  created() {
    this.tokenCheck()
    setInterval(this.tokenExtend, 60000)
  },
  mounted(){
    let a = window.location.href.split("/")
    switch (a[a.length-1]){
      case ("product"):
        this.nowRoute = 0
        break
      case ("Exhibition"):
        this.nowRoute = 1
        break
      case ("Banner"):
        this.nowRoute = 2
        break
      case ("guest"):
        this.nowRoute = 3
        break
      case ("messageinfo"):
      case ("message"):
        this.nowRoute = 4
        break
      case ("userList"):
        this.nowRoute = 5
        break
      case ("user"):
        this.nowRoute = 6
    }
  }
}
</script>

<style>
* {
  box-sizing: border-box;
}
.shou {
  cursor: pointer;
}
html, body, #app, .big {
  height: 100%;
}

.body {
  /*width: 1400px;*/
  width: 100%;
  margin: 0 auto;
}


.header_bac {
  width: 100%;
  box-shadow: #dddddd 0px 5px 7px 1px
}

.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  width: 90%;
}

.header_item {
  display: flex;
  align-items: flex-end;
}

.header_title {
  font-size: 25px;
  color: #4D4D4D;
  font-weight: 300;
  padding-left: 30px;
}

.logged_user_name {
  font-size: 14px;
  letter-spacing: 2px;
  padding-right: 10px;
}

.header_decorate img {
  height: 90px;
}

.main {
  display: flex;
  height: calc(100% - 150px);
  flex-direction: row;
}

.side_nav {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: #d7d7d7 2px solid;
  /*padding-bottom: 740px;*/
  padding-top: 20px;
}

.main_view {
  width: calc(100% - 250px);
}

.side_nav_item {
  width: 75px;
  padding: 15px 0;
  font-size: 16px;

}

.side_nav_item span {
  color: #AFAFAF;
}

.isSelected {
  color: black !important;
}

.footer {
  height: 40px;
  background: #898989;
}

.full-modal {
  margin-top: 120px;
  background: #8c8c8c;
}
@font-face {
  font-family: 'FZZ';
  src: url("@/assets/font/PingFang.ttc");
}
</style>


